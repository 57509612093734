<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <text> callers ID: </text>
    <input type="text" v-model="callerUserID">
    <button @click="init"> step1. init </button> <br>
    <text> callees ID: </text>
    <input type="text" v-model="calleeUserID">
    <button @click="call"> step2. call </button>
    <my-TUICallKit style="width: 100%; height: 100% "></my-TUICallKit>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// 视频通话
import {
  TUICallKit,
  TUICallKitServer,
  TUICallType
} from "@tencentcloud/call-uikit-vue2";

import * as GenerateTestUserSig from "./debug/GenerateTestUserSig-es"; // Refer to Step 3

export default {
  name: 'App',
  components: {
   "my-TUICallKit" : TUICallKit
  },
  data() {
    return {
      SDKAppID: 20011894,
      SDKSecretKey: 'fe2d5dbbe97c12e3efd1b6333a9287c78163fdb87962c143e374ac6c0041e58a',
      callerUserID: '',
      calleeUserID: ''
    }
  },
  methods: {
     init() {
      const { userSig } = GenerateTestUserSig.genTestUserSig({
        userID: this.callerUserID,
        SDKAppID: this.SDKAppID,
        SecretKey: this.SDKSecretKey
      });
       TUICallKitServer.init({
        userID: this.callerUserID,
        userSig,
        SDKAppID: this.SDKAppID,
      });
      alert('TUICallKit init succeed');
    },
    call() {
      TUICallKitServer.call({
        userID: this.calleeUserID,
        type: TUICallType.VIDEO_CALL,
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
